<style lang="scss" scoped>
	h1 {
		margin-bottom: 40px;
	}

	img + p {
		margin-top: 30px;
	}
	p {
		margin-bottom: 10px;
	}
</style>

<template>
	<section>
		<h1>Anfahrt</h1>
		<section>
			<p>gut erreichbar über die A65 von Richtung Ludwigshafen oder Karlsruhe kommend Ausfahrt Neustadt-Süd oder Edenkoben Anfahrt von der Autobahn</p>
			<img src="@/../public/img/anfahrt/maikammer2.jpg" alt="maikammer" />

			<p>Anfahrt in Maikammer</p>
			<img src="@/../public/img/anfahrt/maikammer.gif" alt="maikammer" />
		</section>
	</section>
</template>
